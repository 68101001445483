import { applyMiddleware, combineReducers, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunkMiddleware from 'redux-thunk'

import { LOGOUT_SUCCESS } from './actions/actionTypes'

import app from './reducers/app'
import auth from './reducers/auth.reducer'
import dashboard from './reducers/dashboard.reducer'
import user from './reducers/user.reducer'
import gift from './reducers/gift.reducer'
import transfer from './reducers/transfer.reducer'
import payment from './reducers/payment.reducer'
import comingPurchases from './reducers/comingPurchases.reducer'
import referrals from './reducers/referrals.reducer'
import benefits from './reducers/benefits.reducer'

const reducers = combineReducers({
  app, auth, dashboard, user, gift, transfer, payment, comingPurchases, referrals, benefits
})

const rootReducer = (state, action) => {
  if (action.type === LOGOUT_SUCCESS) {
    state = undefined
  }
  return reducers(state, action)
}

export default () => {
  return createStore(rootReducer, composeWithDevTools(applyMiddleware(thunkMiddleware)))
}
