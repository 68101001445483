import {
  GET_USER_LIST_REQUEST,
  GET_USER_LIST_SUCCESS,
  GET_USER_LIST_FAILURE,
  GET_USER_BY_ID_REQUEST,
  GET_USER_BY_ID_SUCCESS,
  GET_USER_BY_ID_FAILURE,
  GET_USER_BY_EMAIL_REQUEST,
  GET_USER_BY_EMAIL_SUCCESS,
  GET_USER_BY_EMAIL_FAILURE,
  SET_TOTAL_USERS,
  EXPORT_TABLE_REQUEST,
  EXPORT_TABLE_SUCCESS,
  EXPORT_TABLE_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  GET_ONE_UUID_REQUEST,
  GET_ONE_UUID_SUCCESS,
  GET_ONE_UUID_FAILURE,
  FREEZE_USER_REQUEST,
  FREEZE_USER_SUCCESS,
  FREEZE_USER_FAILURE,
  UNFREEZE_USER_REQUEST,
  UNFREEZE_USER_SUCCESS,
  UNFREEZE_USER_FAILURE,
  CLOSE_USER_ACCOUNT_SUCCESS,
  CLOSE_USER_ACCOUNT_FAILURE,
  GET_ONE_ACCOUNT_SUCCESS,
  UPDATE_ACCOUNT_SUCCESS,
  GET_ACCOUNT_BY_ID_REQUEST,
  GET_ACCOUNT_BY_ID_SUCCESS,
  GET_ACCOUNT_BY_ID_FAILURE
} from 'store/actions/actionTypes'

const initialState = {
  userList: [],
  selectedUser: null,
  total: 0,
  loadState: {
    pending: false,
    updatePending: false,
    error: ''
  }
}

export default (state = initialState, action, testvardeleteme) => {
  switch (action.type) {
    case GET_USER_LIST_REQUEST:
      return { ...state, loadState: { pending: true, error: '' } }

    case GET_USER_LIST_SUCCESS:
      return { ...state, userList: action.list, selectedAccount: null, loadState: { pending: false, error: '' } }

    case GET_USER_LIST_FAILURE:
      return { ...state, loadState: { pending: false, error: action.message } }

    case SET_TOTAL_USERS:
      return { ...state, total: action.total }

    case GET_USER_BY_ID_REQUEST:
    case GET_USER_BY_EMAIL_REQUEST:
      return { ...state, loadState: { pending: true, error: '' } }

    case GET_USER_BY_ID_SUCCESS:
      return { ...state, selectedUser: action.user, selectedAccount: action.account, loadState: { pending: false, error: '' } }

    case GET_USER_BY_EMAIL_SUCCESS:
      return { ...state, selectedUser: action.user, selectedAccount: null, loadState: { pending: false, error: '' } }

    case GET_USER_BY_ID_FAILURE:
    case GET_USER_BY_EMAIL_FAILURE:
      return { ...state, loadState: { pending: false, error: action.message } }

    case EXPORT_TABLE_REQUEST:
      return { ...state, loadState: { pending: true, error: '' } }

    case EXPORT_TABLE_SUCCESS:
      return { ...state, loadState: { pending: false, error: '' } }

    case EXPORT_TABLE_FAILURE:
      return { ...state, loadState: { pending: false, error: action.message } }

    case UPDATE_USER_REQUEST:
      return { ...state, loadState: { updatePending: true, error: '' } }

    case UPDATE_USER_SUCCESS:
      const selectedUser = action.data
      return {
        ...state,
        selectedAccount: action.account,
        userList: state.userList.map(user => {
          if (user.id === action.data.id) {
            return { ...user, ...action.data }
          }
          return user
        }),
        selectedUser: state.selectedUser ? selectedUser : state.selectedUser,
        loadState: { updatePending: false, error: action.message }
      }

      case UPDATE_ACCOUNT_SUCCESS:
        return {
          ...state,
          selectedAccount:action.data,
          loadState: { updatePending: false, error: action.message }
        }


    case UPDATE_USER_FAILURE:
      return { ...state, loadState: { updatePending: false, error: action.message } }

    case GET_ONE_UUID_REQUEST:
      return { ...state, loadState: { pending: true, error: '' } }

    case GET_ONE_UUID_SUCCESS:
      return { ...state, userList: action.payload, selectedAccount: null, loadState: { pending: false, error: '' } }

    case GET_ONE_ACCOUNT_SUCCESS:
    return { ...state, selectedAccount: action.payload.accounts[0], userList: action.payload.users, loadState: { pending: false, error: '' } }

    case GET_ONE_UUID_FAILURE:
      return { ...state, loadState: { pending: false, error: action.payload } }

    case FREEZE_USER_REQUEST:
      return {
        ...state,
        loadState: {
          ...state.loadState,
          pending: true,
          error: '',
          notify: action.payload
        }
      }
    case FREEZE_USER_SUCCESS:
      return {
        ...state,
        selectedUser: {
          ...state.selectedUser,
          fraudFrozen: true
        },
        loadState: {
          ...state.loadState,
          pending: false,
          error: ''
        }
      }
    case FREEZE_USER_FAILURE:
      return {
        ...state,
        loadState: {
          ...state.loadState,
          pending: false,
          error: action.payload
        }
      }
    case UNFREEZE_USER_REQUEST:
      return {
        ...state,
        loadState: {
          ...state.loadState,
          pending: true,
          error: ''
        }
      }
    case UNFREEZE_USER_SUCCESS:
      return {
        ...state,
        selectedUser: {
          ...state.selectedUser,
          fraudFrozen: false
        },
        loadState: {
          ...state.loadState,
          pending: false,
          error: ''
        }
      }
    case UNFREEZE_USER_FAILURE:
      return {
        ...state,
        loadState: {
          ...state.loadState,
          pending: false,
          error: action.payload
        }
      }

    case CLOSE_USER_ACCOUNT_SUCCESS:
      return {
        ...state,
        selectedUser: null,
        userList: state.userList.filter((user) => user.id !== action.payload)
      }

    case CLOSE_USER_ACCOUNT_FAILURE:
      return {
        ...state,
        loadState: {
          ...state.loadState,
          error: action.payload
        }
      }

      case GET_ACCOUNT_BY_ID_REQUEST:
      return { ...state, loadState: { pending: true, error: '' } }

      case GET_ACCOUNT_BY_ID_SUCCESS:
      return { ...state, selectedAccount: action.user, loadState: { pending: false, error: '' } }

      case GET_ACCOUNT_BY_ID_FAILURE:
      return { ...state, loadState: { pending: false, error: action.message } }

    default:
      return state
  }
}
