export const CLEAN_ERROR_MESSAGE = 'CLEAN_ERROR_MESSAGE'

export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE'

export const SIGN_UP_REQUEST = 'SIGN_UP_REQUEST'
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS'
export const SIGN_UP_FAILURE = 'SIGN_UP_FAILURE'

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE'

export const UPDATE_PASSWORD_REQUEST = 'UPDATE_PASSWORD_REQUEST'
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS'
export const UPDATE_PASSWORD_FAILURE = 'UPDATE_PASSWORD_FAILURE'

export const GENERATE_DASHBOARD_INFO_REQUEST = 'GENERATE_DASHBOARD_INFO_REQUEST'
export const GENERATE_DASHBOARD_INFO_SUCCESS = 'GENERATE_DASHBOARD_INFO_SUCCESS'
export const GENERATE_DASHBOARD_INFO_FAILURE = 'GENERATE_DASHBOARD_INFO_FAILURE'

export const GET_DASHBOARD_INFO_REQUEST = 'GET_DASHBOARD_INFO_REQUEST'
export const GET_DASHBOARD_INFO_SUCCESS = 'GET_DASHBOARD_INFO_SUCCESS'
export const GET_DASHBOARD_INFO_FAILURE = 'GET_DASHBOARD_INFO_FAILURE'

export const GET_USER_LIST_REQUEST = 'GET_USER_LIST_REQUEST'
export const GET_USER_LIST_SUCCESS = 'GET_USER_LIST_SUCCESS'
export const GET_USER_LIST_FAILURE = 'GET_USER_LIST_FAILURE'

export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST'
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS'
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE'

export const GET_USER_BY_ID_REQUEST = 'GET_USER_BY_ID_REQUEST'
export const GET_USER_BY_ID_SUCCESS = 'GET_USER_BY_ID_SUCCESS'
export const GET_USER_BY_ID_FAILURE = 'GET_USER_BY_ID_FAILURE'

export const GET_USER_BY_EMAIL_REQUEST = 'GET_USER_BY_EMAIL_REQUEST'
export const GET_USER_BY_EMAIL_SUCCESS = 'GET_USER_BY_EMAIL_SUCCESS'
export const GET_USER_BY_EMAIL_FAILURE = 'GET_USER_BY_EMAIL_FAILURE'

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE'

export const CHANGE_EMAIL_REQUEST = 'CHANGE_EMAIL_REQUEST'
export const CHANGE_EMAIL_SUCCESS = 'CHANGE_EMAIL_SUCCESS'
export const CHANGE_EMAIL_FAILURE = 'CHANGE_EMAIL_FAILURE'

export const REMOVE_USER_REQUEST = 'REMOVE_USER_REQUEST'
export const REMOVE_USER_SUCCESS = 'REMOVE_USER_SUCCESS'
export const REMOVE_USER_FAILURE = 'REMOVE_USER_FAILURE'

export const GET_GIFT_LIST_REQUEST = 'GET_GIFT_LIST_REQUEST'
export const GET_GIFT_LIST_SUCCESS = 'GET_GIFT_LIST_SUCCESS'
export const GET_GIFT_LIST_FAILURE = 'GET_GIFT_LIST_FAILURE'

export const GET_GIFT_BY_ID_REQUEST = 'GET_GIFT_BY_ID_REQUEST'
export const GET_GIFT_BY_ID_SUCCESS = 'GET_GIFT_BY_ID_SUCCESS'
export const GET_GIFT_BY_ID_FAILURE = 'GET_GIFT_BY_ID_FAILURE'

export const GET_TRANSFER_LIST_REQUEST = 'GET_TRANSFER_LIST_REQUEST'
export const GET_TRANSFER_LIST_SUCCESS = 'GET_TRANSFER_LIST_SUCCESS'
export const GET_TRANSFER_LIST_FAILURE = 'GET_TRANSFER_LIST_FAILURE'

export const GET_TRANSFER_BY_ID_REQUEST = 'GET_TRANSFER_BY_ID_REQUEST'
export const GET_TRANSFER_BY_ID_SUCCESS = 'GET_TRANSFER_BY_ID_SUCCESS'
export const GET_TRANSFER_BY_ID_FAILURE = 'GET_TRANSFER_BY_ID_FAILURE'

export const GET_PAYMENT_LIST_REQUEST = 'GET_PAYMENT_LIST_REQUEST'
export const GET_PAYMENT_LIST_SUCCESS = 'GET_PAYMENT_LIST_SUCCESS'
export const GET_PAYMENT_LIST_FAILURE = 'GET_PAYMENT_LIST_FAILURE'

export const GET_PAYMENT_BY_ID_REQUEST = 'GET_PAYMENT_BY_ID_REQUEST'
export const GET_PAYMENT_BY_ID_SUCCESS = 'GET_PAYMENT_BY_ID_SUCCESS'
export const GET_PAYMENT_BY_ID_FAILURE = 'GET_PAYMENT_BY_ID_FAILURE'

export const SET_TOTAL = 'SET_TOTAL'
export const SET_TOTAL_USERS = 'SET_TOTAL_USERS'

export const EXPORT_TABLE_REQUEST = 'EXPORT_TABLE_REQUEST'
export const EXPORT_TABLE_SUCCESS = 'EXPORT_TABLE_SUCCESS'
export const EXPORT_TABLE_FAILURE = 'EXPORT_TABLE_FAILURE'

export const GET_COMING_PURCHASES_REQUEST = 'GET_COMING_PURCHASES_REQUEST'
export const GET_COMING_PURCHASES_SUCCESS = 'GET_COMING_PURCHASES_SUCCESS'
export const GET_COMING_PURCHASES_FAILURE = 'GET_COMING_PURCHASES_FAILURE'

export const GET_REFERRALS_REQUEST = 'GET_REFERRALS_REQUEST'
export const GET_REFERRALS_SUCCESS = 'GET_REFERRALS_SUCCESS'
export const GET_REFERRALS_FAILURE = 'GET_REFERRALS_FAILURE'

export const UPDATE_REFERRAL_REQUEST = 'UPDATE_REFERRAL_REQUEST'
export const UPDATE_REFERRAL_SUCCESS = 'UPDATE_REFERRAL_SUCCESS'
export const UPDATE_REFERRAL_FAILURE = 'UPDATE_REFERRAL_FAILURE'

export const DELETE_REFERRAL_REQUEST = 'DELETE_REFERRAL_REQUEST'
export const DELETE_REFERRAL_SUCCESS = 'DELETE_REFERRAL_SUCCESS'
export const DELETE_REFERRAL_FAILURE = 'DELETE_REFERRAL_FAILURE'

export const SEND_WELCOME_EMAIL_REQUEST = 'SEND_WELCOME_EMAIL_REQUEST'
export const SEND_WELCOME_EMAIL_SUCCESS = 'SEND_WELCOME_EMAIL_SUCCESS'
export const SEND_WELCOME_EMAIL_FAILURE = 'SEND_WELCOME_EMAIL_FAILURE'

export const GET_ONE_UUID_REQUEST = 'GET_ONE_UUID_REQUEST '
export const GET_ONE_UUID_SUCCESS = 'GET_ONE_UUID_SUCCESS'
export const GET_ONE_UUID_FAILURE = 'GET_ONE_UUID_FAILURE'

export const FREEZE_USER_REQUEST = 'FREEZE_USER_REQUEST'
export const FREEZE_USER_SUCCESS = 'FREEZE_USER_SUCCESS'
export const FREEZE_USER_FAILURE = 'FREEZE_USER_FAILURE'

export const UNFREEZE_USER_REQUEST = 'UNFREEZE_USER_REQUEST'
export const UNFREEZE_USER_SUCCESS = 'UNFREEZE_USER_SUCCESS'
export const UNFREEZE_USER_FAILURE = 'UNFREEZE_USER_FAILURE'

export const CLOSE_USER_ACCOUNT_REQUEST = 'CLOSE_USER_ACCOUNT_REQUEST'
export const CLOSE_USER_ACCOUNT_SUCCESS = 'CLOSE_USER_ACCOUNT_SUCCESS'
export const CLOSE_USER_ACCOUNT_FAILURE = 'CLOSE_USER_ACCOUNT_FAILURE'

export const PAUSE_USER_ACCOUNT_REQUEST = 'PAUSE_USER_ACCOUNT_REQUEST'

export const REMOVE_USER_PAYMENTS_REQUEST = 'REMOVE_USER_PAYMENTS_REQUEST'

export const GET_BENEFIT_GROUPS_REQUEST = 'GET_BENEFIT_GROUPS_REQUEST'
export const GET_BENEFIT_GROUPS_SUCCESS = 'GET_BENEFIT_GROUPS_SUCCESS'
export const GET_BENEFIT_GROUPS_FAILURE = 'GET_BENEFIT_GROUPS_FAILURE'

export const UPDATE_BENEFIT_PLAN_REQUEST = 'UPDATE_BENEFIT_PLAN_REQUEST'
export const UPDATE_BENEFIT_PLAN_SUCCESS = 'UPDATE_BENEFIT_PLAN_SUCCESS'
export const UPDATE_BENEFIT_PLAN_FAILURE = 'UPDATE_BENEFIT_PLAN_FAILURE'

export const CREATE_BENEFIT_PLAN_REQUEST = 'CREATE_BENEFIT_PLAN_REQUEST'
export const CREATE_BENEFIT_PLAN_SUCCESS = 'CREATE_BENEFIT_PLAN_SUCCESS'
export const CREATE_BENEFIT_PLAN_FAILURE = 'CREATE_BENEFIT_PLAN_FAILURE'

export const CREATE_BENEFIT_GROUP_REQUEST = 'CREATE_BENEFIT_GROUP_REQUEST'
export const CREATE_BENEFIT_GROUP_SUCCESS = 'CREATE_BENEFIT_GROUP_SUCCESS'
export const CREATE_BENEFIT_GROUP_FAILURE = 'CREATE_BENEFIT_GROUP_FAILURE'

export const UPDATE_BENEFIT_GROUP_REQUEST = 'UPDATE_BENEFIT_GROUP_REQUEST'
export const UPDATE_BENEFIT_GROUP_SUCCESS = 'UPDATE_BENEFIT_GROUP_SUCCESS'
export const UPDATE_BENEFIT_GROUP_FAILURE = 'UPDATE_BENEFIT_GROUP_FAILURE'

export const BULK_CREATE_BENEFIT_PLANS_REQUEST = 'BULK_CREATE_BENEFIT_PLANS_REQUEST'
export const BULK_CREATE_BENEFIT_PLANS_SUCCESS = 'BULK_CREATE_BENEFIT_PLANS_SUCCESS'
export const BULK_CREATE_BENEFIT_PLANS_FAILURE = 'BULK_CREATE_BENEFIT_PLANS_FAILURE'

export const BULK_UPDATE_BENEFIT_PLANS_REQUEST = 'BULK_UPDATE_BENEFIT_PLANS_REQUEST'
export const BULK_UPDATE_BENEFIT_PLANS_SUCCESS = 'BULK_UPDATE_BENEFIT_PLANS_SUCCESS'
export const BULK_UPDATE_BENEFIT_PLANS_FAILURE = 'BULK_UPDATE_BENEFIT_PLANS_FAILURE'

export const RETRY_PENDING_INTERNAL_TRANSFERS_REQUEST = 'RETRY_PENDING_INTERNAL_TRANSFERS_REQUEST'
export const RETRY_PENDING_INTERNAL_TRANSFERS_SUCCESS = 'RETRY_PENDING_INTERNAL_TRANSFERS_SUCCESS'
export const RETRY_PENDING_INTERNAL_TRANSFERS_FAILURE = 'RETRY_PENDING_INTERNAL_TRANSFERS_FAILURE'

export const GET_ACCOUNT_BY_ID_FAILURE = 'GET_ACCOUNT_BY_ID_FAILURE'
export const GET_ACCOUNT_BY_ID_REQUEST = 'GET_ACCOUNT_BY_ID_REQUEST'
export const GET_ACCOUNT_BY_ID_SUCCESS = 'GET_ACCOUNT_BY_ID_SUCCESS'

export const GET_ONE_ACCOUNT_REQUEST = 'GET_ONE_ACCOUNT_REQUEST'
export const GET_ONE_ACCOUNT_SUCCESS = 'GET_ONE_ACCOUNT_SUCCESS'
export const GET_ONE_ACCOUNT_FAILURE = 'GET_ONE_ACCOUNT_FAILURE'

export const UPDATE_ACCOUNT_REQUEST = 'UPDATE_ACCOUNT_REQUEST'
export const UPDATE_ACCOUNT_SUCCESS = 'UPDATE_ACCOUNT_SUCCESS'
export const UPDATE_ACCOUNT_FAILURE = 'UPDATE_ACCOUNT_FAILURE'

export const CREATE_USERS_WITH_FLAGS_REQUEST = 'CREATE_USERS_WITH_FLAGS_REQUEST'
export const CREATE_USERS_WITH_FLAGS_SUCCESS = 'CREATE_USERS_WITH_FLAGS_SUCCESS'
export const CREATE_USERS_WITH_FLAGS_FAILURE = 'CREATE_USERS_WITH_FLAGS_FAILURE'
