import {
  GET_BENEFIT_GROUPS_REQUEST,
  GET_BENEFIT_GROUPS_SUCCESS,
  GET_BENEFIT_GROUPS_FAILURE,
  UPDATE_BENEFIT_PLAN_REQUEST,
  UPDATE_BENEFIT_PLAN_SUCCESS,
  UPDATE_BENEFIT_PLAN_FAILURE,
  CREATE_BENEFIT_PLAN_REQUEST,
  CREATE_BENEFIT_PLAN_SUCCESS,
  CREATE_BENEFIT_PLAN_FAILURE,
  CREATE_BENEFIT_GROUP_REQUEST,
  CREATE_BENEFIT_GROUP_SUCCESS,
  CREATE_BENEFIT_GROUP_FAILURE,
  UPDATE_BENEFIT_GROUP_REQUEST,
  UPDATE_BENEFIT_GROUP_SUCCESS,
  UPDATE_BENEFIT_GROUP_FAILURE,
  BULK_CREATE_BENEFIT_PLANS_REQUEST,
  BULK_CREATE_BENEFIT_PLANS_SUCCESS,
  BULK_CREATE_BENEFIT_PLANS_FAILURE,
  BULK_UPDATE_BENEFIT_PLANS_REQUEST,
  BULK_UPDATE_BENEFIT_PLANS_SUCCESS,
  BULK_UPDATE_BENEFIT_PLANS_FAILURE
} from '../actions/actionTypes'

const initialState = {
  list: [],
  loading: false,
  error: '',
  loadState: {
    pending: false,
    updatePending: false,
    error: ''
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_BENEFIT_GROUPS_REQUEST:
      return {
        ...state,
        loading: true
      }

    case GET_BENEFIT_GROUPS_SUCCESS:
      return {
        ...state,
        list: action.payload,
        loading: false
      }

    case GET_BENEFIT_GROUPS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      }

    case UPDATE_BENEFIT_PLAN_REQUEST:
      return { ...state, loadState: { updatePending: true, error: '' } }

    case UPDATE_BENEFIT_PLAN_SUCCESS:
      return {
        ...state,
        list: state.list.map(group => {
          const plans = group.plans.map(plan => {
            if (plan.id === action.data.id) {
              return { ...plan, ...action.data }
            }
            return plan
          })
          group.plans = plans
          return group
        }),
        loadState: { updatePending: false, error: action.message }
      }

    case UPDATE_BENEFIT_PLAN_FAILURE:
      return { ...state, loadState: { updatePending: false, error: action.message } }

    case CREATE_BENEFIT_PLAN_REQUEST:
      return { ...state, loadState: { updatePending: true, error: '' } }

    case CREATE_BENEFIT_PLAN_SUCCESS:
      return {
        ...state,
        list: state.list.map(group => {
          const plans = group.plans
          if (group.id === action.data.internalTransferPlanGroupId) {
            plans.unshift({ ...action.data })
          }

          group.plans = plans
          return group
        }),
        loadState: { updatePending: false, error: action.message }
      }

    case CREATE_BENEFIT_PLAN_FAILURE:
      return { ...state, loadState: { updatePending: false, error: action.message } }

    case CREATE_BENEFIT_GROUP_REQUEST:
      return { ...state, loadState: { updatePending: true, error: '' } }

    case CREATE_BENEFIT_GROUP_SUCCESS:
      const groups = state.list
      groups.unshift({ ...action.data })
      return {
        ...state,
        list: groups,
        loadState: { updatePending: false, error: action.message }
      }

    case CREATE_BENEFIT_GROUP_FAILURE:
      return { ...state, loadState: { updatePending: false, error: action.message } }

    case UPDATE_BENEFIT_GROUP_REQUEST:
      return { ...state, loadState: { updatePending: true, error: '' } }

    case UPDATE_BENEFIT_GROUP_SUCCESS:
      return {
        ...state,
        list: state.list.map(group => {
          if (group.id === action.data.id) {
            return { ...group, ...action.data }
          }
          return group
        }),
        loadState: { updatePending: false, error: action.message }
      }

    case UPDATE_BENEFIT_GROUP_FAILURE:
      return { ...state, loadState: { updatePending: false, error: action.message } }

    case BULK_CREATE_BENEFIT_PLANS_REQUEST:
      return { ...state, loadState: { updatePending: true, error: '' } }

    case BULK_CREATE_BENEFIT_PLANS_SUCCESS:
      return {
        ...state,
        list: state.list.map(group => {
          let plans = group.plans
          if (group.id === action.data.plans[0].internalTransferPlanGroupId) {
            plans = action.data.plans
          }

          group.plans = plans
          return group
        }),
        loadState: { updatePending: false, error: action.message }
      }

    case BULK_CREATE_BENEFIT_PLANS_FAILURE:
      return { ...state, loadState: { updatePending: false, error: action.message } }

    case BULK_UPDATE_BENEFIT_PLANS_REQUEST:
      return { ...state, loadState: { updatePending: true, error: '' } }

    case BULK_UPDATE_BENEFIT_PLANS_SUCCESS:
      return {
        ...state,
        list: state.list.map(group => {
          let plans = group.plans
          if (group.id === action.data.plans[0].internalTransferPlanGroupId) {
            plans = action.data.plans
          }

          group.plans = plans
          return group
        }),
        loadState: { updatePending: false, error: action.message }
      }

    case BULK_UPDATE_BENEFIT_PLANS_FAILURE:
      return { ...state, loadState: { updatePending: false, error: action.message } }

    default:
      return state
  }
}
