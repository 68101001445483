import {
  GENERATE_DASHBOARD_INFO_REQUEST,
  GENERATE_DASHBOARD_INFO_SUCCESS,
  GENERATE_DASHBOARD_INFO_FAILURE,
  GET_DASHBOARD_INFO_REQUEST,
  GET_DASHBOARD_INFO_SUCCESS,
  GET_DASHBOARD_INFO_FAILURE
} from 'store/actions/actionTypes'

const initialState = {
  info: null,
  generateNew: true,
  loadState: {
    pending: false,
    error: ''
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GENERATE_DASHBOARD_INFO_REQUEST:
      return { ...state, loadState: { pending: true, error: '' } }

    case GENERATE_DASHBOARD_INFO_SUCCESS:
      return { ...state, generateNew: false, loadState: { pending: false, error: '' } }

    case GENERATE_DASHBOARD_INFO_FAILURE:
      return { ...state, loadState: { pending: false, error: action.message } }
    
    case GET_DASHBOARD_INFO_REQUEST:
      return { ...state, loadState: { pending: true, error: '' } }

    case GET_DASHBOARD_INFO_SUCCESS:
      return { ...state, info: action.data, loadState: { pending: false, error: '' } }

    case GET_DASHBOARD_INFO_FAILURE:
      return { ...state, loadState: { pending: false, error: action.message } }

    default:
      return state
  }
}
