import React from 'react'
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'

import databind from 'helpers/redux'
import './App.scss'

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./pages/Login'))
const Register = React.lazy(() => import('./pages/Register'))
const Page404 = React.lazy(() => import('./pages/Page404'))
const Page500 = React.lazy(() => import('./pages/Page500'))

const App = ({ user }) => {
    return (
      <HashRouter>
        <React.Suspense fallback={loading()}>
          {user
            ? <Switch>
              <Route path="/" name="Home" render={props => <DefaultLayout {...props}/>} />
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
              <Redirect from="*" to='/404' />
            </Switch>
            : <Switch>
              <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
              <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
              <Redirect from="*" to='/login' />
            </Switch>
          }
        </React.Suspense>
      </HashRouter>
    )
}

App.propTypes = { user: PropTypes.object }

App.databind = state => ({ user: state.auth.user })

export default databind(App)
