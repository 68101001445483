import {
  GET_REFERRALS_REQUEST,
  GET_REFERRALS_SUCCESS,
  GET_REFERRALS_FAILURE,
  SET_TOTAL,
  UPDATE_REFERRAL_REQUEST,
  UPDATE_REFERRAL_SUCCESS,
  UPDATE_REFERRAL_FAILURE,
  DELETE_REFERRAL_REQUEST,
  DELETE_REFERRAL_SUCCESS,
  DELETE_REFERRAL_FAILURE,
  SEND_WELCOME_EMAIL_REQUEST,
  SEND_WELCOME_EMAIL_SUCCESS,
  SEND_WELCOME_EMAIL_FAILURE,
} from '../actions/actionTypes'

const initialState = {
  list: [],
  loading: false,
  error: '',
  emailError: '',
  total: 0,
  loadState: {
    pending: false,
    updatePending: false,
    error: ''
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_REFERRALS_REQUEST:
      return {
        ...state,
        loading: true
      }

    case GET_REFERRALS_SUCCESS:
      return {
        ...state,
        list: action.payload,
        loading: false
      }

    case GET_REFERRALS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      }

    case SET_TOTAL:
      return { ...state, total: action.total }

    case UPDATE_REFERRAL_REQUEST:
      return { ...state, loadState: { updatePending: true, error: '' } }

    case UPDATE_REFERRAL_SUCCESS:

      return {
        ...state,
        list: state.list.map(referral => {
          if (referral.id === action.data.id) {
            return { ...referral, ...action.data }
          }
          return referral
        }),
        loadState: { updatePending: false, error: action.message }
      }

    case UPDATE_REFERRAL_FAILURE:
      return { ...state, loadState: { updatePending: false, error: action.message } }

    case DELETE_REFERRAL_REQUEST:
      return { ...state, loadState: { error: '' } }

    case DELETE_REFERRAL_SUCCESS:

      return {
        ...state,
        list: state.list.filter(referral => {
          return referral.id !== action.data.id
        }),
        loadState: { error: action.message }
      }

    case DELETE_REFERRAL_FAILURE:
      return { ...state, loadState: { error: action.message } }

    case SEND_WELCOME_EMAIL_REQUEST:
      return { ...state, loadState: { emailError: '' } }

    case SEND_WELCOME_EMAIL_SUCCESS:

      return {
        ...state,
        loadState: { emailError: action.message }
      }

    case SEND_WELCOME_EMAIL_FAILURE:
      return { ...state, emailError: action.message }

    default:
      return state
  }
}
